import { AuthService, LoginDto, SignupDto } from "@/api";
import { Injectable, inject } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AuthApiService {
  private readonly authService = inject(AuthService);

  login(body: LoginDto) {
    return this.authService.authControllerLogin({ body });
  }

  logout() {
    return this.authService.authControllerLogout();
  }

  signup(body: SignupDto) {
    return this.authService.authControllerSignup({ body });
  }
  verifyEmail(token: string) {
    return this.authService.authControllerVerifyEmail({ token });
  }
  getAccessTokenByRefreshToken() {
    return this.authService.authControllerGetAccessTokenByRefreshToken();
  }
}
