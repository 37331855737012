import { ApiModule } from "@/api";
import { provideHttpClient, withInterceptors } from "@angular/common/http";
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
} from "@angular/core";
import {
  PreloadAllModules,
  provideRouter,
  withPreloading,
} from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { environment } from "../environments/environment";
import { appRoutes } from "./app.routes";
import { GlobalErrorHandler } from "./errors/global-error.handler";
import { AuthFactory } from "./factories/auth.factory";
import { ApiInterceptorFn } from "./interceptors/api.interceptor";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes, withPreloading(PreloadAllModules)),
    provideHttpClient(withInterceptors([ApiInterceptorFn])),
    importProvidersFrom(
      ApiModule.forRoot({
        rootUrl: environment.BASE_URL,
      }),
      IonicModule.forRoot({
        mode: "md",
      }),
    ),
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: AuthFactory,
      multi: true,
    },
  ],
};
