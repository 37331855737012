import { CreateUserDto, UpdateUserDto, UserDto, UserService } from "@/api";
import { Injectable, OnDestroy, inject } from "@angular/core";
import { UserControllerFindAll$Params } from "api/src/lib/fn/user/user-controller-find-all";
import { UserControllerSearch$Params } from "api/src/lib/fn/user/user-controller-search";
import { Observable, Subject, shareReplay, takeUntil } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UserApiService implements OnDestroy {
  private readonly userService = inject(UserService);
  private readonly clearCache$ = new Subject<void>();
  private cache$?: Observable<UserDto[]>;

  ngOnDestroy() {
    this.clearCache$.next();
    this.clearCache$.complete();
  }

  me() {
    return this.userService.userControllerMe();
  }

  search(options: UserControllerSearch$Params) {
    return this.userService.userControllerSearch(options);
  }

  findAll(options: UserControllerFindAll$Params) {
    if (!this.cache$) {
      this.cache$ = this.userService
        .userControllerFindAll(options)
        .pipe(shareReplay(1), takeUntil(this.clearCache$));
    }
    return this.cache$;
  }

  findById(id: string) {
    return this.userService.userControllerFindById({ id });
  }

  create(body: CreateUserDto) {
    return this.userService.userControllerCreate({ body });
  }

  update(id: string, body: UpdateUserDto) {
    return this.userService.userControllerUpdate({ id, body });
  }

  delete(id: string) {
    return this.userService.userControllerDelete({ id });
  }

  clearCache = () => {
    this.clearCache$.next();
    this.cache$ = undefined;
  };

  refresh = (options?: UserControllerFindAll$Params) => {
    this.clearCache();
    return this.findAll(options ?? {});
  };
}
