import { inject } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { EMPTY, catchError, switchMap, tap } from "rxjs";
import { AuthApiService } from "../services/auth-api.service";
import { UserApiService } from "../services/user-api.service";
import { AuthStore } from "../stores/auth.store";

export const AuthFactory = () => {
  const authStore = inject(AuthStore);
  const authApi = inject(AuthApiService);
  const userApi = inject(UserApiService);
  const cookieService = inject(CookieService);
  const getAuthRequest$ = userApi.me().pipe(
    catchError(() => {
      authStore.clearAuth();
      return EMPTY;
    }),
    tap((user) => authStore.setAuth(user)),
  );
  const getAccessToken$ = authApi
    .getAccessTokenByRefreshToken()
    .pipe(switchMap(() => getAuthRequest$));
  return () => {
    const accessToken = cookieService.get("X-Authorization");
    const refreshToken = cookieService.get("X-RefreshToken");
    if (!accessToken && !refreshToken) return authStore.clearAuth();
    if (!accessToken && refreshToken) return getAccessToken$;
    return getAuthRequest$;
  };
};
