import {
  HttpErrorResponse,
  HttpHandlerFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError } from 'rxjs';
import { HttpErrorHandler } from '../errors/http-error.handler';

export function ApiInterceptorFn(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) {
  const httpErrorHandler = inject(HttpErrorHandler);
  const apiReq = req.clone({
    withCredentials: true,
  });
  return next(apiReq).pipe(
    catchError((error) => {
      if (error instanceof HttpErrorResponse) {
        httpErrorHandler.handleError(error);
      }
      throw error;
    }),
  );
}
