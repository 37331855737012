import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { ToastService } from "../services/toast.service";
import { AuthStore } from "../stores/auth.store";

export const AuthGuard = (next: ActivatedRouteSnapshot) => {
  const authService = inject(AuthStore);
  const router = inject(Router);
  const toastService = inject(ToastService);
  const auth = authService.auth$.value;

  if (!auth) {
    toastService.show("로그인을 해주세요", "danger");
    router.navigate(["login"]);
    return false;
  }

  return true;
};
