/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { authControllerGetAccessTokenByRefreshToken } from '../fn/auth/auth-controller-get-access-token-by-refresh-token';
import { AuthControllerGetAccessTokenByRefreshToken$Params } from '../fn/auth/auth-controller-get-access-token-by-refresh-token';
import { authControllerLogin } from '../fn/auth/auth-controller-login';
import { AuthControllerLogin$Params } from '../fn/auth/auth-controller-login';
import { authControllerLogout } from '../fn/auth/auth-controller-logout';
import { AuthControllerLogout$Params } from '../fn/auth/auth-controller-logout';
import { authControllerSignup } from '../fn/auth/auth-controller-signup';
import { AuthControllerSignup$Params } from '../fn/auth/auth-controller-signup';
import { authControllerVerifyEmail } from '../fn/auth/auth-controller-verify-email';
import { AuthControllerVerifyEmail$Params } from '../fn/auth/auth-controller-verify-email';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `authControllerSignup()` */
  static readonly AuthControllerSignupPath = '/api/v1/auth/signup';

  /**
   * 회원가입.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerSignup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerSignup$Response(params: AuthControllerSignup$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authControllerSignup(this.http, this.rootUrl, params, context);
  }

  /**
   * 회원가입.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerSignup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerSignup(params: AuthControllerSignup$Params, context?: HttpContext): Observable<void> {
    return this.authControllerSignup$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authControllerVerifyEmail()` */
  static readonly AuthControllerVerifyEmailPath = '/api/v1/auth/verify-email';

  /**
   * 이메일 인증.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerVerifyEmail()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerVerifyEmail$Response(params: AuthControllerVerifyEmail$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authControllerVerifyEmail(this.http, this.rootUrl, params, context);
  }

  /**
   * 이메일 인증.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerVerifyEmail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerVerifyEmail(params: AuthControllerVerifyEmail$Params, context?: HttpContext): Observable<void> {
    return this.authControllerVerifyEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authControllerLogin()` */
  static readonly AuthControllerLoginPath = '/api/v1/auth/login';

  /**
   * 로그인.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerLogin$Response(params: AuthControllerLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authControllerLogin(this.http, this.rootUrl, params, context);
  }

  /**
   * 로그인.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerLogin(params: AuthControllerLogin$Params, context?: HttpContext): Observable<void> {
    return this.authControllerLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authControllerLogout()` */
  static readonly AuthControllerLogoutPath = '/api/v1/auth/logout';

  /**
   * 로그아웃.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerLogout()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerLogout$Response(params?: AuthControllerLogout$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authControllerLogout(this.http, this.rootUrl, params, context);
  }

  /**
   * 로그아웃.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerLogout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerLogout(params?: AuthControllerLogout$Params, context?: HttpContext): Observable<void> {
    return this.authControllerLogout$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authControllerGetAccessTokenByRefreshToken()` */
  static readonly AuthControllerGetAccessTokenByRefreshTokenPath = '/api/v1/auth/refresh';

  /**
   * 토큰 재발급.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerGetAccessTokenByRefreshToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerGetAccessTokenByRefreshToken$Response(params?: AuthControllerGetAccessTokenByRefreshToken$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authControllerGetAccessTokenByRefreshToken(this.http, this.rootUrl, params, context);
  }

  /**
   * 토큰 재발급.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerGetAccessTokenByRefreshToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerGetAccessTokenByRefreshToken(params?: AuthControllerGetAccessTokenByRefreshToken$Params, context?: HttpContext): Observable<void> {
    return this.authControllerGetAccessTokenByRefreshToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
