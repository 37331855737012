/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CreatePresignedUrlDto } from '../../models/create-presigned-url-dto';
import { ResponsePresignedUrlDto } from '../../models/response-presigned-url-dto';

export interface ImageControllerCreatePresignedUrl$Params {
      body: CreatePresignedUrlDto
}

export function imageControllerCreatePresignedUrl(http: HttpClient, rootUrl: string, params: ImageControllerCreatePresignedUrl$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponsePresignedUrlDto>> {
  const rb = new RequestBuilder(rootUrl, imageControllerCreatePresignedUrl.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponsePresignedUrlDto>;
    })
  );
}

imageControllerCreatePresignedUrl.PATH = '/api/v1/images/presigned-url';
