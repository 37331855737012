import { ErrorHandler, inject, Injectable } from "@angular/core";
import { ToastService } from "../services/toast.service";
import { UserFriendlyError } from "./user-friendly.error";

@Injectable({
  providedIn: "root",
})
export class GlobalErrorHandler implements ErrorHandler {
  toastService = inject(ToastService);
  handleError(error: any): void {
    if (error instanceof UserFriendlyError) {
      this.toastService.show(error.message, "danger");
    }
  }
}
