<ion-header class="border-b ion-no-border">
  <ion-toolbar>
    <nav
      class="flex justify-between items-center lg:container lg:mx-auto h-[64px]"
    >
      <ion-img
        class="h-[20px] px-5 cursor-pointer"
        routerLink="/"
        src="icons/logo.svg"
      ></ion-img>
      <div
        class="items-center hidden gap-4 text-base font-bold lg:flex text-grey700"
      >
        <a routerLink="/company">회사소개</a>
        <a routerLink="/affiliate">제휴거래소</a>
        <a routerLink="/event">이벤트</a>
        <a routerLink="/apply-affiliate">제휴사문의</a>
      </div>
      @if (isLoggedIn) {
        <ion-buttons class="hidden px-7 lg:flex lg:gap-[10px]" slot="end">
          <button
            routerLink="/my-page"
            class="bg-blue px-[12px] py-[10px] rounded-[8px] bg-blue600"
          >
            <span class="text-sm font-bold text-white">마이페이지</span>
          </button>
        </ion-buttons>
      }
      @if (!isLoggedIn) {
        <ion-buttons class="hidden px-7 lg:flex lg:gap-[10px]" slot="end">
          <button
            routerLink="/login"
            class="px-[12px] py-[10px] rounded-[8px] bg-blue50"
          >
            <span class="text-sm font-bold text-blue500">로그인</span>
          </button>
          <button
            routerLink="/signup"
            class="bg-blue px-[12px] py-[10px] rounded-[8px] bg-blue600"
          >
            <span class="text-sm font-bold text-white">회원가입</span>
          </button>
        </ion-buttons>
      }
      @if (isLoggedIn) {
        <ion-buttons class="flex gap-2 px-4 lg:hidden" slot="end">
          <ion-icon
            routerLink="/my-page"
            class="text-gray-700 cursor-pointer size-7"
            name="person-circle-outline"
          ></ion-icon>
        </ion-buttons>
      }
      @if (!isLoggedIn) {
        <ion-buttons class="flex gap-2 px-4 lg:hidden" slot="end">
          <ion-icon
            routerLink="/login"
            class="text-gray-700 cursor-pointer size-7"
            name="log-in-outline"
          ></ion-icon>
          <ion-icon
            routerLink="/signup"
            class="text-gray-700 cursor-pointer size-6"
            name="person-add-outline"
          ></ion-icon>
        </ion-buttons>
      }
    </nav>
  </ion-toolbar>
  <div
    class="flex items-center pl-4 text-grey700 text-[15px] font-medium gap-4 h-[42px] w-full py-1 overflow-x-auto bg-white border-t lg:hidden"
  >
    <a class="whitespace-nowrap" routerLink="/company">회사소개</a>
    <a class="whitespace-nowrap" routerLink="/affiliate">제휴거래소</a>
    <a class="whitespace-nowrap" routerLink="/event">이벤트</a>
    <a class="whitespace-nowrap" routerLink="/apply-affiliate">제휴사문의</a>
  </div>
</ion-header>
