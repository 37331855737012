/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { businessInfoControllerFind } from '../fn/business-info/business-info-controller-find';
import { BusinessInfoControllerFind$Params } from '../fn/business-info/business-info-controller-find';
import { businessInfoControllerUpdate } from '../fn/business-info/business-info-controller-update';
import { BusinessInfoControllerUpdate$Params } from '../fn/business-info/business-info-controller-update';
import { BusinessInfoDto } from '../models/business-info-dto';

@Injectable({ providedIn: 'root' })
export class BusinessInfoService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `businessInfoControllerFind()` */
  static readonly BusinessInfoControllerFindPath = '/api/v1/businessInfos';

  /**
   * BusinessInfo 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessInfoControllerFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  businessInfoControllerFind$Response(params?: BusinessInfoControllerFind$Params, context?: HttpContext): Observable<StrictHttpResponse<BusinessInfoDto>> {
    return businessInfoControllerFind(this.http, this.rootUrl, params, context);
  }

  /**
   * BusinessInfo 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessInfoControllerFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  businessInfoControllerFind(params?: BusinessInfoControllerFind$Params, context?: HttpContext): Observable<BusinessInfoDto> {
    return this.businessInfoControllerFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<BusinessInfoDto>): BusinessInfoDto => r.body)
    );
  }

  /** Path part for operation `businessInfoControllerUpdate()` */
  static readonly BusinessInfoControllerUpdatePath = '/api/v1/businessInfos';

  /**
   * BusinessInfo 수정.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessInfoControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  businessInfoControllerUpdate$Response(params: BusinessInfoControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<BusinessInfoDto>> {
    return businessInfoControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * BusinessInfo 수정.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessInfoControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  businessInfoControllerUpdate(params: BusinessInfoControllerUpdate$Params, context?: HttpContext): Observable<BusinessInfoDto> {
    return this.businessInfoControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<BusinessInfoDto>): BusinessInfoDto => r.body)
    );
  }

}
