/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { imageControllerCreate } from '../fn/image/image-controller-create';
import { ImageControllerCreate$Params } from '../fn/image/image-controller-create';
import { imageControllerCreatePresignedUrl } from '../fn/image/image-controller-create-presigned-url';
import { ImageControllerCreatePresignedUrl$Params } from '../fn/image/image-controller-create-presigned-url';
import { imageControllerDelete } from '../fn/image/image-controller-delete';
import { ImageControllerDelete$Params } from '../fn/image/image-controller-delete';
import { ImageDto } from '../models/image-dto';
import { ResponsePresignedUrlDto } from '../models/response-presigned-url-dto';

@Injectable({ providedIn: 'root' })
export class ImageService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `imageControllerCreate()` */
  static readonly ImageControllerCreatePath = '/api/v1/images';

  /**
   * Image 생성.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `imageControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  imageControllerCreate$Response(params: ImageControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<ImageDto>> {
    return imageControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Image 생성.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `imageControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  imageControllerCreate(params: ImageControllerCreate$Params, context?: HttpContext): Observable<ImageDto> {
    return this.imageControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ImageDto>): ImageDto => r.body)
    );
  }

  /** Path part for operation `imageControllerCreatePresignedUrl()` */
  static readonly ImageControllerCreatePresignedUrlPath = '/api/v1/images/presigned-url';

  /**
   * Presigned URL 생성.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `imageControllerCreatePresignedUrl()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  imageControllerCreatePresignedUrl$Response(params: ImageControllerCreatePresignedUrl$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponsePresignedUrlDto>> {
    return imageControllerCreatePresignedUrl(this.http, this.rootUrl, params, context);
  }

  /**
   * Presigned URL 생성.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `imageControllerCreatePresignedUrl$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  imageControllerCreatePresignedUrl(params: ImageControllerCreatePresignedUrl$Params, context?: HttpContext): Observable<ResponsePresignedUrlDto> {
    return this.imageControllerCreatePresignedUrl$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponsePresignedUrlDto>): ResponsePresignedUrlDto => r.body)
    );
  }

  /** Path part for operation `imageControllerDelete()` */
  static readonly ImageControllerDeletePath = '/api/v1/images/{id}';

  /**
   * Image 삭제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `imageControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  imageControllerDelete$Response(params: ImageControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return imageControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Image 삭제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `imageControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  imageControllerDelete(params: ImageControllerDelete$Params, context?: HttpContext): Observable<void> {
    return this.imageControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
