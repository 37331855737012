import { CommonModule } from "@angular/common";
import { Component, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { AuthStore } from "../../stores/auth.store";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  standalone: true,
  imports: [CommonModule, IonicModule, RouterModule],
})
export default class HeaderComponent {
  authStore = inject(AuthStore);
  isLoggedIn = false;
  companyStyle = {
    "--background": "transparent",
    position: "absolute",
    top: 0,
    color: "white",
  };
  constructor() {
    this.authStore.isLoggedIn$
      .pipe(takeUntilDestroyed())
      .subscribe((isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      });
  }
}
