import { ErrorMessage } from "@/common";
import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { switchMap } from "rxjs";
import { AuthApiService } from "../services/auth-api.service";
import { ToastService } from "../services/toast.service";
import { AuthStore } from "../stores/auth.store";
import { UserFriendlyError } from "./user-friendly.error";
import { UserApiService } from "../services/user-api.service";

function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

@Injectable({
  providedIn: "root",
})
export class HttpErrorHandler implements ErrorHandler {
  authStore = inject(AuthStore);
  toastService = inject(ToastService);
  router = inject(Router);
  cookieService = inject(CookieService);
  authApi = inject(AuthApiService);
  userApi = inject(UserApiService);

  handleError(httpError: HttpErrorResponse) {
    const status: number = httpError.status;
    const error: any = httpError.error;
    const response = isJsonString(error) ? JSON.parse(error) : error;

    if (status === 0) {
      this.toastService.show("서버에 연결할 수 없습니다.", "danger");
      return;
    }

    if (status === 401) {
      this.handleUnauthorized();
      return;
    }

    if (status === 498) {
      this.handleInvalidToken();
      return;
    }

    throw new UserFriendlyError(
      ErrorMessage[response.error.code] ??
        response.message ??
        "알 수 없는 오류가 발생했습니다.",
    );
  }

  private async handleInvalidToken() {
    this.authStore.clearAuth();
    this.authApi
      .getAccessTokenByRefreshToken()
      .pipe(switchMap(() => this.userApi.me()))
      .subscribe((user) => {
        if (user) {
          this.authStore.setAuth(user);
        }
      });
  }

  private async handleUnauthorized(): Promise<void> {
    this.authStore.clearAuth();
    this.toastService.show(
      "사용 권한이 없습니다. 다시 로그인해주세요.",
      "danger",
    );
    this.router.navigateByUrl("/login");
  }
}
