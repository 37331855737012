/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { affiliateControllerCreate } from '../fn/affiliate/affiliate-controller-create';
import { AffiliateControllerCreate$Params } from '../fn/affiliate/affiliate-controller-create';
import { affiliateControllerDelete } from '../fn/affiliate/affiliate-controller-delete';
import { AffiliateControllerDelete$Params } from '../fn/affiliate/affiliate-controller-delete';
import { affiliateControllerFindAll } from '../fn/affiliate/affiliate-controller-find-all';
import { AffiliateControllerFindAll$Params } from '../fn/affiliate/affiliate-controller-find-all';
import { affiliateControllerFindById } from '../fn/affiliate/affiliate-controller-find-by-id';
import { AffiliateControllerFindById$Params } from '../fn/affiliate/affiliate-controller-find-by-id';
import { affiliateControllerSearch } from '../fn/affiliate/affiliate-controller-search';
import { AffiliateControllerSearch$Params } from '../fn/affiliate/affiliate-controller-search';
import { affiliateControllerUpdate } from '../fn/affiliate/affiliate-controller-update';
import { AffiliateControllerUpdate$Params } from '../fn/affiliate/affiliate-controller-update';
import { AffiliateDto } from '../models/affiliate-dto';
import { PaginationDto } from '../models/pagination-dto';

@Injectable({ providedIn: 'root' })
export class AffiliateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `affiliateControllerSearch()` */
  static readonly AffiliateControllerSearchPath = '/api/v1/affiliates/search';

  /**
   * Affiliate 검색.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `affiliateControllerSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  affiliateControllerSearch$Response(params: AffiliateControllerSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<PaginationDto>> {
    return affiliateControllerSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Affiliate 검색.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `affiliateControllerSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  affiliateControllerSearch(params: AffiliateControllerSearch$Params, context?: HttpContext): Observable<PaginationDto> {
    return this.affiliateControllerSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaginationDto>): PaginationDto => r.body)
    );
  }

  /** Path part for operation `affiliateControllerFindAll()` */
  static readonly AffiliateControllerFindAllPath = '/api/v1/affiliates';

  /**
   * 모든 Affiliate 목록 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `affiliateControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  affiliateControllerFindAll$Response(params?: AffiliateControllerFindAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AffiliateDto>>> {
    return affiliateControllerFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 모든 Affiliate 목록 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `affiliateControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  affiliateControllerFindAll(params?: AffiliateControllerFindAll$Params, context?: HttpContext): Observable<Array<AffiliateDto>> {
    return this.affiliateControllerFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AffiliateDto>>): Array<AffiliateDto> => r.body)
    );
  }

  /** Path part for operation `affiliateControllerCreate()` */
  static readonly AffiliateControllerCreatePath = '/api/v1/affiliates';

  /**
   * Affiliate 생성.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `affiliateControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  affiliateControllerCreate$Response(params: AffiliateControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<AffiliateDto>> {
    return affiliateControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Affiliate 생성.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `affiliateControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  affiliateControllerCreate(params: AffiliateControllerCreate$Params, context?: HttpContext): Observable<AffiliateDto> {
    return this.affiliateControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AffiliateDto>): AffiliateDto => r.body)
    );
  }

  /** Path part for operation `affiliateControllerFindById()` */
  static readonly AffiliateControllerFindByIdPath = '/api/v1/affiliates/{id}';

  /**
   * Affiliate id로 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `affiliateControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  affiliateControllerFindById$Response(params: AffiliateControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<AffiliateDto>> {
    return affiliateControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Affiliate id로 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `affiliateControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  affiliateControllerFindById(params: AffiliateControllerFindById$Params, context?: HttpContext): Observable<AffiliateDto> {
    return this.affiliateControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AffiliateDto>): AffiliateDto => r.body)
    );
  }

  /** Path part for operation `affiliateControllerDelete()` */
  static readonly AffiliateControllerDeletePath = '/api/v1/affiliates/{id}';

  /**
   * Affiliate 삭제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `affiliateControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  affiliateControllerDelete$Response(params: AffiliateControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return affiliateControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Affiliate 삭제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `affiliateControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  affiliateControllerDelete(params: AffiliateControllerDelete$Params, context?: HttpContext): Observable<void> {
    return this.affiliateControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `affiliateControllerUpdate()` */
  static readonly AffiliateControllerUpdatePath = '/api/v1/affiliates/{id}';

  /**
   * Affiliate 수정.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `affiliateControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  affiliateControllerUpdate$Response(params: AffiliateControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<AffiliateDto>> {
    return affiliateControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Affiliate 수정.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `affiliateControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  affiliateControllerUpdate(params: AffiliateControllerUpdate$Params, context?: HttpContext): Observable<AffiliateDto> {
    return this.affiliateControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AffiliateDto>): AffiliateDto => r.body)
    );
  }

}
