import { UserDto } from '@/api';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthStore implements OnDestroy {
  auth$ = new BehaviorSubject<UserDto | undefined>(undefined);
  isLoggedIn$ = this.auth$.asObservable().pipe(map((user) => !!user));
  ngOnDestroy(): void {
    this.auth$.complete();
  }
  setAuth(user: UserDto) {
    this.auth$.next(user);
  }
  getAuth() {
    return this.auth$.getValue();
  }
  clearAuth() {
    this.auth$.next(undefined);
  }
}
