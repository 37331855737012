/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { inquiryControllerAnswer } from '../fn/inquiry/inquiry-controller-answer';
import { InquiryControllerAnswer$Params } from '../fn/inquiry/inquiry-controller-answer';
import { inquiryControllerCreate } from '../fn/inquiry/inquiry-controller-create';
import { InquiryControllerCreate$Params } from '../fn/inquiry/inquiry-controller-create';
import { inquiryControllerDelete } from '../fn/inquiry/inquiry-controller-delete';
import { InquiryControllerDelete$Params } from '../fn/inquiry/inquiry-controller-delete';
import { inquiryControllerFindAll } from '../fn/inquiry/inquiry-controller-find-all';
import { InquiryControllerFindAll$Params } from '../fn/inquiry/inquiry-controller-find-all';
import { inquiryControllerFindById } from '../fn/inquiry/inquiry-controller-find-by-id';
import { InquiryControllerFindById$Params } from '../fn/inquiry/inquiry-controller-find-by-id';
import { inquiryControllerSearch } from '../fn/inquiry/inquiry-controller-search';
import { InquiryControllerSearch$Params } from '../fn/inquiry/inquiry-controller-search';
import { inquiryControllerUpdate } from '../fn/inquiry/inquiry-controller-update';
import { InquiryControllerUpdate$Params } from '../fn/inquiry/inquiry-controller-update';
import { InquiryDto } from '../models/inquiry-dto';
import { PaginationDto } from '../models/pagination-dto';

@Injectable({ providedIn: 'root' })
export class InquiryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `inquiryControllerSearch()` */
  static readonly InquiryControllerSearchPath = '/api/v1/inquiries/search';

  /**
   * Inquiry 검색.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inquiryControllerSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerSearch$Response(params: InquiryControllerSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<PaginationDto>> {
    return inquiryControllerSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Inquiry 검색.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inquiryControllerSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerSearch(params: InquiryControllerSearch$Params, context?: HttpContext): Observable<PaginationDto> {
    return this.inquiryControllerSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaginationDto>): PaginationDto => r.body)
    );
  }

  /** Path part for operation `inquiryControllerFindAll()` */
  static readonly InquiryControllerFindAllPath = '/api/v1/inquiries';

  /**
   * 모든 Inquiry 목록 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inquiryControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerFindAll$Response(params?: InquiryControllerFindAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<InquiryDto>>> {
    return inquiryControllerFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 모든 Inquiry 목록 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inquiryControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerFindAll(params?: InquiryControllerFindAll$Params, context?: HttpContext): Observable<Array<InquiryDto>> {
    return this.inquiryControllerFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<InquiryDto>>): Array<InquiryDto> => r.body)
    );
  }

  /** Path part for operation `inquiryControllerCreate()` */
  static readonly InquiryControllerCreatePath = '/api/v1/inquiries';

  /**
   * Inquiry 생성.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inquiryControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inquiryControllerCreate$Response(params: InquiryControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<InquiryDto>> {
    return inquiryControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Inquiry 생성.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inquiryControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inquiryControllerCreate(params: InquiryControllerCreate$Params, context?: HttpContext): Observable<InquiryDto> {
    return this.inquiryControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<InquiryDto>): InquiryDto => r.body)
    );
  }

  /** Path part for operation `inquiryControllerFindById()` */
  static readonly InquiryControllerFindByIdPath = '/api/v1/inquiries/{id}';

  /**
   * Inquiry id로 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inquiryControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerFindById$Response(params: InquiryControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<InquiryDto>> {
    return inquiryControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Inquiry id로 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inquiryControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerFindById(params: InquiryControllerFindById$Params, context?: HttpContext): Observable<InquiryDto> {
    return this.inquiryControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<InquiryDto>): InquiryDto => r.body)
    );
  }

  /** Path part for operation `inquiryControllerDelete()` */
  static readonly InquiryControllerDeletePath = '/api/v1/inquiries/{id}';

  /**
   * Inquiry 삭제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inquiryControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerDelete$Response(params: InquiryControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return inquiryControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Inquiry 삭제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inquiryControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerDelete(params: InquiryControllerDelete$Params, context?: HttpContext): Observable<void> {
    return this.inquiryControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `inquiryControllerUpdate()` */
  static readonly InquiryControllerUpdatePath = '/api/v1/inquiries/{id}';

  /**
   * Inquiry 수정.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inquiryControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inquiryControllerUpdate$Response(params: InquiryControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<InquiryDto>> {
    return inquiryControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Inquiry 수정.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inquiryControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inquiryControllerUpdate(params: InquiryControllerUpdate$Params, context?: HttpContext): Observable<InquiryDto> {
    return this.inquiryControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<InquiryDto>): InquiryDto => r.body)
    );
  }

  /** Path part for operation `inquiryControllerAnswer()` */
  static readonly InquiryControllerAnswerPath = '/api/v1/inquiries/{id}/answer';

  /**
   * Inquiry 답변.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inquiryControllerAnswer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inquiryControllerAnswer$Response(params: InquiryControllerAnswer$Params, context?: HttpContext): Observable<StrictHttpResponse<InquiryDto>> {
    return inquiryControllerAnswer(this.http, this.rootUrl, params, context);
  }

  /**
   * Inquiry 답변.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inquiryControllerAnswer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inquiryControllerAnswer(params: InquiryControllerAnswer$Params, context?: HttpContext): Observable<InquiryDto> {
    return this.inquiryControllerAnswer$Response(params, context).pipe(
      map((r: StrictHttpResponse<InquiryDto>): InquiryDto => r.body)
    );
  }

}
