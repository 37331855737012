import { Directive, ElementRef, Input, afterNextRender } from '@angular/core';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types';

@Directive({
  selector: '[swiper]',
  standalone: true,
})
export class SwiperDirective {
  @Input('options') options?: SwiperOptions;
  constructor(private element: ElementRef<SwiperContainer>) {
    afterNextRender(() => {
      Object.assign(this.element.nativeElement, this.options);
      this.element.nativeElement.initialize();
    });
  }
}
