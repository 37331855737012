/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { eventControllerCreate } from '../fn/event/event-controller-create';
import { EventControllerCreate$Params } from '../fn/event/event-controller-create';
import { eventControllerDelete } from '../fn/event/event-controller-delete';
import { EventControllerDelete$Params } from '../fn/event/event-controller-delete';
import { eventControllerFindAll } from '../fn/event/event-controller-find-all';
import { EventControllerFindAll$Params } from '../fn/event/event-controller-find-all';
import { eventControllerFindById } from '../fn/event/event-controller-find-by-id';
import { EventControllerFindById$Params } from '../fn/event/event-controller-find-by-id';
import { eventControllerSearch } from '../fn/event/event-controller-search';
import { EventControllerSearch$Params } from '../fn/event/event-controller-search';
import { eventControllerUpdate } from '../fn/event/event-controller-update';
import { EventControllerUpdate$Params } from '../fn/event/event-controller-update';
import { EventDto } from '../models/event-dto';
import { PaginationDto } from '../models/pagination-dto';

@Injectable({ providedIn: 'root' })
export class EventService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `eventControllerSearch()` */
  static readonly EventControllerSearchPath = '/api/v1/events/search';

  /**
   * Event 검색.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerSearch$Response(params: EventControllerSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<PaginationDto>> {
    return eventControllerSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Event 검색.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerSearch(params: EventControllerSearch$Params, context?: HttpContext): Observable<PaginationDto> {
    return this.eventControllerSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaginationDto>): PaginationDto => r.body)
    );
  }

  /** Path part for operation `eventControllerFindAll()` */
  static readonly EventControllerFindAllPath = '/api/v1/events';

  /**
   * 모든 Event 목록 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindAll$Response(params?: EventControllerFindAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EventDto>>> {
    return eventControllerFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 모든 Event 목록 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindAll(params?: EventControllerFindAll$Params, context?: HttpContext): Observable<Array<EventDto>> {
    return this.eventControllerFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EventDto>>): Array<EventDto> => r.body)
    );
  }

  /** Path part for operation `eventControllerCreate()` */
  static readonly EventControllerCreatePath = '/api/v1/events';

  /**
   * Event 생성.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerCreate$Response(params: EventControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<EventDto>> {
    return eventControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Event 생성.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerCreate(params: EventControllerCreate$Params, context?: HttpContext): Observable<EventDto> {
    return this.eventControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventDto>): EventDto => r.body)
    );
  }

  /** Path part for operation `eventControllerFindById()` */
  static readonly EventControllerFindByIdPath = '/api/v1/events/{id}';

  /**
   * Event id로 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindById$Response(params: EventControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<EventDto>> {
    return eventControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Event id로 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindById(params: EventControllerFindById$Params, context?: HttpContext): Observable<EventDto> {
    return this.eventControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventDto>): EventDto => r.body)
    );
  }

  /** Path part for operation `eventControllerDelete()` */
  static readonly EventControllerDeletePath = '/api/v1/events/{id}';

  /**
   * Event 삭제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerDelete$Response(params: EventControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return eventControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Event 삭제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerDelete(params: EventControllerDelete$Params, context?: HttpContext): Observable<void> {
    return this.eventControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `eventControllerUpdate()` */
  static readonly EventControllerUpdatePath = '/api/v1/events/{id}';

  /**
   * Event 수정.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerUpdate$Response(params: EventControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<EventDto>> {
    return eventControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Event 수정.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerUpdate(params: EventControllerUpdate$Params, context?: HttpContext): Observable<EventDto> {
    return this.eventControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventDto>): EventDto => r.body)
    );
  }

}
